<template>
  <Modal
    v-model="isShow"
    title="编辑会员等级"
    width="600"
    class-name="i-modal"
    ok-text="保存"
  >
    <Form
      :model="obj"
      label-position="right"
      :rules="validateRules"
      ref="form"
      :label-width="120"
    >
      <Row :gutter="16">
        <Col span="24">
          <FormItem label="等级图标:">
            <i-upload
              :fileKey="uploadType.MemberLevel"
              @onChange="onChange"
              ref="UploadLogo"
              :max="1"
            ></i-upload>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="16">
        <Col span="12">
          <FormItem label="等级名称:" prop="LevelName">
            <Input v-model="obj.LevelName" />
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem label="升级数量:" prop="UpGradeNum">
            <Input v-model="obj.UpGradeNum" />
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span="24">
          <FormItem label="备注:" prop="Remark">
            <Input type="text" v-model="obj.Remark" />
          </FormItem>
        </Col>
      </Row>
    </Form>
    <div slot="footer">
      <Button @click="isShow = false">取消</Button>
      <Button type="primary" @click="handleSave" :loading="isSaving">
        保存
      </Button>
    </div>
  </Modal>
</template>

<script>
import bizEnum from "@/libs/bizEnum";
import api from "@/api";
import iUpload from "@/components/upload/index.vue";

export default {
  name: "member-level-edit",
  components: { iUpload },
  data() {
    const validatePositveInt = (rule, value, callback) => {
      if (isNaN(Number(value))) {
        callback(new Error("请输入正整数"));
      } else if (value < 0) {
        callback(new Error("数值不能小于0"));
      } else if (Math.floor(value) !== Number(value)) {
        callback(new Error("请输入正整数"));
      } else {
        callback();
      }
    };
    return {
      isEdit: false,
      isSaving: false,
      isShow: false,
      logoFile: [],
      obj: {},
      validateRules: {
        LevelName: [{ required: true, message: "等级名称不能为空" }],
        UpGradeNum: [
          { required: true, message: "升级数量不能为空" },
          { validator: validatePositveInt, trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    isShow: function (val) {
      if (val) {
        // 关闭
        this.obj.IsShowStr = this.obj.IsShow ? 1 : 0;
        if (this.obj.Logo) {
          this.$refs.UploadLogo.uploadFiles = [
            {
              _key: this.obj.Logo,
              _file: this.$setImagePrefix(this.obj.Logo),
              success: true,
            },
          ];
        } else {
          this.$refs.UploadLogo.uploadFiles = [];
        }
      }
    },
  },
  methods: {
    async handleSave() {
      const _this = this;
      _this.isSaving = true;
      _this.$refs.form.validate(async (valid) => {
        if (valid) {
          // if (!_this.$refs.UploadLogo.uploadFiles.length) {
          //   _this.isSaving = false;
          //   return _this.$Message.error('请上传等级图标');
          // }
          if (
            _this.$refs.UploadLogo.uploadFiles.length &&
            _this.$refs.UploadLogo.uploadFiles.filter((f) => !f.success).length
          ) {
            const res = await _this.$refs.UploadLogo.Upload();
            if (res.success.length === 0) {
              _this.isSaving = false;
              return _this.$Message.error("等级图标上传失败");
            } else {
              _this.obj.Logo = res.success[0];
            }
          } else if (!_this.$refs.UploadLogo.uploadFiles.length) {
            _this.obj.Logo = null;
          }
          const result = await api.EditMemberLevel({
            memberLevel: _this.obj,
          });
          _this.$emit("on-save");
          _this.isSaving = false;
          _this.obj = result.Data;
          _this.isShow = false;
          _this.$Message.success("保存成功");
        } else {
          _this.isSaving = false;
          _this.$Message.error("填写信息有误！");
        }
      });
    },
    onChange(opt) {
      this.logoFile = opt.files;
    },
  },
  computed: {
    uploadType() {
      return bizEnum.UploadType;
    },
  },
};
</script>
